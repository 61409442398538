import toast from 'react-hot-toast';

export const handleCatchError = (err: unknown) => {
  if (err instanceof Error) {
    const untypedError = err as any;
    if (untypedError.response?.data?.error && typeof untypedError.response?.data?.error === 'string') {
      toast.error(untypedError.response.data.error, { duration: 7000 });
    } else {
      toast.error(String(err.message), { duration: 7000 });
    }
  } else {
    console.error('Unknown Error');
  }
};
