import axiosClient from '../../utils/API';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_REMOVE_TOKEN = 'AUTH_REMOVE_TOKEN';
export const REFRESH_USER_DATA = 'REFRESH_USER_DATA';

export const authSetToken = (token, user) => ({
  type: AUTH_SET_TOKEN,
  payload: { token, user },
});

export const authRemoveToken = () => ({
  type: AUTH_REMOVE_TOKEN,
});

export const refreshUserData = (fresh_user) => ({
  type: REFRESH_USER_DATA,
  payload: { fresh_user },
});

export function fetchMeUser() {
  return (dispatch) => {
    axiosClient
      .get('/users/me')
      .then((result) => {
        if (!result.data) {
          dispatch(authRemoveToken());
        }
        dispatch(refreshUserData(result.data));
        return result.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(authRemoveToken());
        return;
      });
  };
}
