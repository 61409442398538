import { FETCH_PARTNERS_BEGIN, FETCH_PARTNERS_FAILURE, FETCH_PARTNERS_SUCCESS } from './partnerActions';

const initialState = {
  items: [],
  loading: true,
  error: null,
};

export default function partnersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PARTNERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.partners,
      };
    case FETCH_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
