import { CLOSE_PAYMENT_INFO_MODAL, OPEN_PAYMENT_INFO_MODAL } from './addPaymentInfoActions';

const initialState = {
  loading: false,
  error: null,
  modalOpen: false,
};

export default function addPaymentInfoReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_PAYMENT_INFO_MODAL:
      return {
        ...state,
        modalOpen: true,
      };
    case CLOSE_PAYMENT_INFO_MODAL:
      return {
        ...state,
        modalOpen: false,
      };
    default:
      return state;
  }
}
