import axiosClient from '../../utils/API';
import toast from 'react-hot-toast';

export const ADD_EDITED_FIELD_VALUE = 'ADD_EDITED_FIELD_VALUE';

export const POST_EDITED_ORDER_BEGIN = 'POST_EDITED_ORDER_BEGIN';
export const POST_EDITED_ORDER_SUCCESS = 'POST_EDITED_ORDER_SUCCESS';
export const POST_EDITED_ORDER_ERROR = 'POST_EDITED_ORDER_ERROR';

export const UPDATE_QUOTE_BEGIN = 'UPDATE_QUOTE_BEGIN';
export const UPDATE_QUOTE_SUCCESS = 'UPDATE_QUOTE_SUCCESS';
export const UPDATE_QUOTE_ERROR = 'UPDATE_QUOTE_ERROR';

export const ORDER_DELETE_BEGIN = 'ORDER_DELETE_BEGIN';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';
export const ORDER_DELETE_ERROR = 'ORDER_DELETE_ERROR';

export const ADD_TO_TOTAL = 'ADD_TO_TOTAL';
export const SUBTRACT_FROM_TOTAL = 'SUBTRACT_FROM_TOTAL';

export const ORDER_CHARGE_BEGIN = 'ORDER_CHARGE_BEGIN';
export const ORDER_CHARGE_SUCCESS = 'ORDER_CHARGE_SUCCESS';
export const ORDER_CHARGE_ERROR = 'ORDER_CHARGE_ERROR';

export const addEditedFieldValue = (field, value) => ({
  type: ADD_EDITED_FIELD_VALUE,
  payload: { field, value },
});

export const updateQuoteDetailBegin = () => ({
  type: UPDATE_QUOTE_BEGIN,
});

export const updateQuoteDetailSuccess = (order) => ({
  type: UPDATE_QUOTE_SUCCESS,
  payload: { order },
});

export const updateQuoteDetailError = (error) => ({
  type: UPDATE_QUOTE_ERROR,
  payload: { error },
});

export const orderDeleteBegin = () => ({
  type: ORDER_DELETE_BEGIN,
});

export const orderDeleteSuccess = () => ({
  type: ORDER_DELETE_SUCCESS,
});

export const orderDeleteError = (error) => ({
  type: ORDER_DELETE_ERROR,
  payload: { error },
});

export const addToTotal = (amount) => ({
  type: ADD_TO_TOTAL,
  payload: { amount },
});

export const subtractFromTotal = (amount) => ({
  type: SUBTRACT_FROM_TOTAL,
  payload: { amount },
});

export const orderChargeBegin = () => ({
  type: ORDER_CHARGE_BEGIN,
});

export const orderChargeSuccess = () => ({
  type: ORDER_CHARGE_SUCCESS,
});

export const orderChargeError = (error) => ({
  type: ORDER_CHARGE_ERROR,
  payload: { error },
});

export function updateQuoteDetail(id, quote_body) {
  return (dispatch) => {
    dispatch(updateQuoteDetailBegin());
    axiosClient
      .put(`/quote/${id}`, quote_body)
      .then((result) => {
        dispatch(updateQuoteDetailSuccess(result.data.result));
        return result.data.result;
      })
      .catch((error) => {
        dispatch(updateQuoteDetailError(error));
      });
  };
}

export function startOrderDelete(id) {
  return (dispatch) => {
    dispatch(orderDeleteBegin());
    axiosClient
      .delete(`/order/${id}`)
      .then((result) => {
        dispatch(orderDeleteSuccess());
        return result.data;
      })
      .catch((error) => {
        dispatch(orderDeleteError(error));
      });
  };
}

export function chargeOrder(sourceId, amount, orderId, sig, rating, tip, hasDamage, damageDescription) {
  return (dispatch) => {
    dispatch(orderChargeBegin());

    axiosClient
      .post(`/stripe/source/${sourceId}/charge`, {
        amount,
        orderId,
        sig,
        rating,
        tip,
        hasDamage: Boolean(hasDamage === 'true'),
        damageDescription: hasDamage === 'true' ? damageDescription : '',
      })
      .then((result) => {
        dispatch(orderChargeSuccess());
        return result.data;
      })
      .catch((error) => {
        console.error(error);
        console.error(error?.response || 'no response');
        console.error(error?.response?.data || 'no response data');
        toast.error('Payment failed');
        // shotgun approach to error handling
        if (error?.response?.data) {
          toast.error(error?.response?.data?.error?.code || error?.response?.data?.code || 'Unknown error code');
          toast.error(error?.response?.data?.error?.message || error?.response?.data?.message || 'Unknown error message');
        } else {
          toast.error("Unable to parse error message - check console.logs")
        }

        dispatch(orderChargeError(error));
      });
  };
}

export function completeOrderWithoutCard(orderId, sig, rating, hasDamage, damageDescription) {
  return (dispatch) => {
    dispatch(orderChargeBegin());

    axiosClient
      .post(`/stripe/source/${orderId}/complete`, {
        orderId,
        sig,
        rating,
        hasDamage: Boolean(hasDamage === 'true'),
        damageDescription: hasDamage === 'true' ? damageDescription : '',
      })
      .then((result) => {
        dispatch(orderChargeSuccess());
        return result.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(orderChargeError(error));
      });
  };
}
