import {
  END_USER_EDIT,
  FETCH_USER_BEGIN,
  FETCH_USER_ERROR,
  FETCH_USER_SUCCESS,
  START_USER_EDIT,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  START_CHANGE_PASSWORD,
  END_CHANGE_PASSWORD,
} from './userDetailActions';

const initialState = {
  user: null,
  loading: true,
  error: null,
  editing: false,
  passwordModalOpen: false,
};

export default function userDetailReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
      };
    case START_CHANGE_PASSWORD:
      return {
        ...state,
        passwordModalOpen: true,
      };
    case END_CHANGE_PASSWORD:
      return {
        ...state,
        passwordModalOpen: false,
      };
    case START_USER_EDIT:
      return {
        ...state,
        editing: true,
      };
    case END_USER_EDIT:
      return {
        ...state,
        editing: false,
      };
    case UPDATE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          ...action.payload.updated_user,
        },
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
