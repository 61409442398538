import {
  FETCH_TIMESLOTS_FAILURE,
  FETCH_TIMESLOTS_BEGIN,
  FETCH_TIMESLOTS_SUCCESS,
  OPEN_SLOT_MODAL,
  CLOSE_SLOT_MODAL,
  POST_SLOT_BEGIN,
  POST_SLOT_FAILURE,
  POST_SLOT_SUCCESS,
  DELETE_SLOT_BEGIN,
  DELETE_SLOT_FAILURE,
  DELETE_SLOT_SUCCESS,
  UPDATE_SLOT_BEGIN,
  UPDATE_SLOT_FAILURE,
  UPDATE_SLOT_SUCCESS,
} from './scheduleActions';

const initialState = {
  items: [],
  loading: true,
  error: null,
  slotModalOpen: false,
  postLoading: false,
  singleSlot: null,
  deleteLoading: false,
  updateLoading: false,
};

export default function scheduleReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SLOT_MODAL:
      return {
        ...state,
        slotModalOpen: true,
        singleSlot: action.payload.event,
      };
    case CLOSE_SLOT_MODAL:
      return {
        ...state,
        slotModalOpen: false,
      };
    case FETCH_TIMESLOTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TIMESLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.slots,
        error: null,
      };
    case FETCH_TIMESLOTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case POST_SLOT_BEGIN:
      return {
        ...state,
        postLoading: true,
        error: null,
      };
    case POST_SLOT_SUCCESS:
      return {
        ...state,
        postLoading: false,
        items: [...state.items, action.payload.slot],
        error: null,
      };
    case POST_SLOT_FAILURE:
      return {
        ...state,
        postLoading: false,
        error: action.payload.error,
      };
    case UPDATE_SLOT_BEGIN:
      return {
        ...state,
        updateLoading: true,
        error: null,
      };
    case UPDATE_SLOT_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        slotModalOpen: false,
      };
    case UPDATE_SLOT_FAILURE:
      return {
        ...state,
        updateLoading: false,
        error: action.payload.error,
      };
    case DELETE_SLOT_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };
    case DELETE_SLOT_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        items: state.items.filter((i) => action.payload.slot.id !== i.id),
      };
    case DELETE_SLOT_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
