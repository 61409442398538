import React, { useContext, useEffect, useRef, useState } from 'react';

import { FormState } from '../types/Form';
import { FormVariablesContext } from './FormVariablesContext';
import { Partner } from '../types/Partners';
import { calculateDrivingDistancesForFormState } from '../utils/calculateDrivingDistancesForFormState';
import { computeCostsAndHours } from '../utils/costAndHoursAlgorithm';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/es6';
import { handleCatchError } from '../utils/handleCatchError';

type FormContextProps = {
  isLoading: boolean;
  formState: FormState;
  toggleIsQuote: () => void;
  setFormState: (formState: FormState) => void;
  wizardStep: number;
  setWizardStep: (wizardStep: number) => void;
  animationDirection: 'forward' | 'back';
  setAnimationDirection: (animationDirection: 'forward' | 'back') => void;
  nextStep: () => void;
  previousStep: () => void;
  updatePartner: (partner: { label: string; value: string; partner: Partner } | null) => void;
  resetForm: () => void;
  auth_state?: {
    token?: string;
    loggedInUser?: { name: string };
  };
  isUserLoggedInAndAdmin: boolean; // @TODO this doesnt belong here but i dont want to deal with wrapping every component that needs it
};

export const getBlankFormState = (variablesId: string): FormState => ({
  service: null,
  isQuote: false,
  partner: null,
  pickup_location: null,
  dropoff_location: null,
  dondisp_location: null,
  labor_location: null,
  estimated_cost: 0,
  hours_estimate: 0,
  paymentType: 'Collect On Delivery',
  variablesId,
  description_of_work: '',
  office_to_pickup_miles: null,
  office_to_pickup_hours: null,
  pickup_to_dropoff_miles: null,
  pickup_to_dropoff_hours: null,
  item_delivery_form_data: {
    number_of_items_array: [],
    stairs_yes_no: null,
    elevator_yes_no: null,
    heavy_yes_no: null,
    item_delivery_location: null,
    assembly_yes_no: null,
    assembly_minutes: null,
    per_item_fee: null,
    stairs_fee: null,
    elevator_fee: null,
    assembly_fee: null,
    heavy_fee: null,
    deduction_amount: null,
    fee_total: null,
  },
  dondisp_form_data: {
    total_cost_norange: null,
    pricerange_min: null,
    pricerange_max: null,
    miles: null,
    time: null,
    stairs_elevator_required: null,
    stuff_object: {
      value: null,
      time: null,
    },
    refrigerated_amount: null,
  },
  labor_form_data: {
    total_labor_fees: null,
    hours_estimate: null,
    labor_miles: null,
    labor_time: { time: '', fee: null },
  },
});

export const FormContext = React.createContext<FormContextProps>({} as FormContextProps);

export const FormContextProviderWithoutAuth = ({ children, auth_state }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [wizardStep, setWizardStep] = useState(0);
  const [formState, setFormState] = useState<FormState>(getBlankFormState(''));
  const [animationDirection, setAnimationDirection] = useState<'forward' | 'back'>('forward');

  const { variablesInContext } = useContext(FormVariablesContext);

  const isUserLoggedInAndAdmin = Boolean(auth_state && auth_state.loggedInUser && auth_state.loggedInUser.admin);

  const resetForm = () => {
    setFormState(
      getBlankFormState(variablesInContext?.variables?.length ? variablesInContext?.variables[0].id || '' : '')
    );
  };

  const toggleIsQuote = () => {
    setFormState({ ...formState, isQuote: Boolean(!formState.isQuote) });
  };

  const nextStep = () => {
    setWizardStep(wizardStep + 1);
    setAnimationDirection('forward');
  };

  const previousStep = () => {
    if (wizardStep === 0) return;
    setWizardStep(wizardStep - 1);
    setAnimationDirection('back');
  };

  const updatePartner = (partner: { label: string; value: string; partner: Partner } | null) => {
    setFormState({
      ...formState,
      partner: partner?.partner || null,
      paymentType: partner?.partner.defaultPaymentMethod || 'Collect On Delivery',
    });
  };

  const previousFormState = useRef<FormState | undefined>(formState);

  useEffect(() => {
    const { pickup_location, dropoff_location, labor_location, dondisp_location } = formState;
    const calculateDistances = async () => {
      try {
        if (!variablesInContext) return;
        const updatedFormState = await calculateDrivingDistancesForFormState(formState, variablesInContext);
        setFormState(updatedFormState);
      } catch (err) {
        handleCatchError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (
      !equal(pickup_location, previousFormState.current?.pickup_location) ||
      !equal(dropoff_location, previousFormState.current?.dropoff_location) ||
      !equal(dondisp_location, previousFormState.current?.dondisp_location) ||
      !equal(labor_location, previousFormState.current?.labor_location)
    ) {
      calculateDistances();
    }

    previousFormState.current = formState;
  }, [formState, variablesInContext]);

  useEffect(() => {
    if (!variablesInContext) return;

    const { formState: newFormState } = computeCostsAndHours(formState, variablesInContext);
    setFormState(newFormState);

    // only update when we change the wizard step
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardStep]);

  return (
    <FormContext.Provider
      value={{
        isLoading,
        nextStep,
        previousStep,
        formState,
        toggleIsQuote,
        resetForm,
        setFormState,
        updatePartner,
        wizardStep,
        setWizardStep,
        animationDirection,
        setAnimationDirection,
        isUserLoggedInAndAdmin,
        auth_state,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

function mapStateToProps(state: any) {
  return {
    auth_state: state.auth,
  };
}

const mapDispatchToProps = {};

export const FormContextProviderWithAuth = connect(mapStateToProps, mapDispatchToProps)(FormContextProviderWithoutAuth);
