export const serviceTypes: Service[] = [
  {
    name: 'Store Delivery',
    modifiedName: 'Store to Home Delivery',
    description: 'Pick up from a store and deliver to my home',
    pathname: '/store-to-home-delivery',
  },
  {
    name: 'Craigslist Delivery',
    modifiedName: 'Home to Home Delivery',
    description: 'Pick up from a residence and deliver to my home',
    pathname: '/home-to-home-delivery',
  },
  {
    name: 'Labor Only',
    modifiedName: 'Labor Only',
    description: 'No truck necessary I just need labor.',
    pathname: '/labor-only',
  },
  {
    name: 'Donation/Disposal',
    modifiedName: 'Donation/Disposal',
    description: 'Come take away my old and unwanted items',
    pathname: '/donation-disposal',
  },
];

export const ServiceNames = ['Store Delivery', 'Craigslist Delivery', 'Labor Only', 'Donation/Disposal'] as const;

export type ServiceNames = typeof ServiceNames[number];

export type Service = {
  name: ServiceNames;
  modifiedName: string;
  description: string;
  pathname: string;
};

export const serviceTypesV2: ServiceUpdatedV2[] = [
  {
    name: 'Store Delivery',
    modifiedName: 'Store to Home Delivery',
    description: 'Pick up from a store and deliver to my home',
    pathname: '/store-to-home-delivery',
  },
  {
    name: 'Craigslist Delivery',
    modifiedName: 'Home to Home Delivery',
    description: 'Pick up from a residence and deliver to my home',
    pathname: '/home-to-home-delivery',
  },
  {
    name: 'Labor Only',
    modifiedName: 'Labor Only',
    description: 'No truck necessary I just need labor.',
    pathname: '/labor-only',
  },
];

export type ServiceUpdatedV2 = {
  name: ServiceNamesUpdatedV2;
  modifiedName: string;
  description: string;
  pathname: string;
};

export const ServiceNamesUpdatedV2 = ['Store Delivery', 'Craigslist Delivery', 'Labor Only'] as const;

export type ServiceNamesUpdatedV2 = typeof ServiceNamesUpdatedV2[number];