import './assets/global.css';

import React, { Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Analytics } from '@vercel/analytics/react';
import CreateStore from './store/configureStore';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { FormContextProviderWithAuth } from './contexts/FormContext';
import { FormVariablesContextProvider } from './contexts/FormVariablesContext';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { PersistGate } from 'redux-persist/integration/react';
import PrivateRoute from './components/Router/PrivateRoute';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import RouteLoading from './components/RouteLoading';
import SentryErrorBoundary from './components/SentryErrorBoundary';
import { SnackbarProvider } from 'notistack';
import { StripeProvider } from 'react-stripe-elements';
import { Toaster } from 'react-hot-toast';
import axiosClient from './utils/API';
import { serviceTypes } from './types/ServiceTypes';

const WizardFormPage = lazy(() => import('./pages/WizardFormPage'));
const DashboardPage = lazy(() => import('./pages/Dashboard'));
const LoginPage = lazy(() => import('./pages/Login'));
const BlockedPage = lazy(() => import('./pages/Blocked'));
const NoMatchPage = lazy(() => import('./pages/NoMatch'));
const PartnersPage = lazy(() => import('./pages/Partners'));

const { persistor, store } = CreateStore();

axiosClient.interceptors.request.use(
  function (config) {
    const token = store.getState().auth.token;
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    console.log('error', error);
    return Promise.reject(error);
  }
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#202020',
    },
    secondary: {
      main: '#c11a21',
    },
  },
});

function App() {
  ReactGA.initialize('G-D4MYD17DCR');
  // if we are in a development process, set the stripe key to the test key
  let stripeKey = 'pk_live_jPK1T58INfdY5O1lFQC3oo1u00y3UNfp4b';
  if (process.env.NODE_ENV === 'development') {
    stripeKey = 'pk_test_12KIrRgHGnNBgEUceUoHkHy200ECqfNbzs';
  }

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  });

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <SnackbarProvider maxSnack={3}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StripeProvider apiKey={stripeKey}>
              <ThemeProvider theme={theme}>
                <SentryErrorBoundary>
                  <Analytics />
                  <Toaster
                    position="top-center"
                    containerStyle={{ zIndex: 99999 }}
                    toastOptions={{
                      style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                      },
                    }}
                  />
                  <FormVariablesContextProvider>
                    <FormContextProviderWithAuth>
                      <Router>
                        <Suspense fallback={<RouteLoading />}>
                          <Switch>
                            {process.env.NODE_ENV !== 'development' && (
                              <Route
                                path="/"
                                exact
                                component={() => {
                                  window.location.href = 'https://firstduemovers.com/on-demand-delivery/';
                                  return null;
                                }}
                              />
                            )}

                            {serviceTypes.map((service) => (
                              <Route key={service.pathname} path={service.pathname} exact component={WizardFormPage} />
                            ))}

                            <Route path="/login/" component={LoginPage} />
                            <Route path="/blocked" component={BlockedPage} />
                            <Route path="/partner" component={PartnersPage} />
                            <PrivateRoute path="/admin/" component={DashboardPage} />
                            <Route component={NoMatchPage} />
                          </Switch>
                        </Suspense>
                      </Router>
                    </FormContextProviderWithAuth>
                  </FormVariablesContextProvider>
                </SentryErrorBoundary>
              </ThemeProvider>
            </StripeProvider>
          </PersistGate>
        </Provider>
      </SnackbarProvider>
    </LocalizationProvider>
  );
}

export default App;
