import axiosClient from '../../utils/API';
import { parseFromTimeZone } from 'date-fns-timezone';

export const FETCH_DAY_ORDERS_BEGIN = 'FETCH_DAY_ORDERS_BEGIN';
export const FETCH_DAY_ORDERS_SUCCESS = 'FETCH_DAY_ORDERS_SUCCESS';
export const FETCH_DAY_ORDERS_FAILURE = 'FETCH_DAY_ORDERS_FAILURE';

export const fetchDayOrdersBegin = () => ({
  type: FETCH_DAY_ORDERS_BEGIN,
});

export const fetchDayOrdersSuccess = (orders) => ({
  type: FETCH_DAY_ORDERS_SUCCESS,
  payload: { orders },
});

export const fetchDayOrdersFailure = (error) => ({
  type: FETCH_DAY_ORDERS_FAILURE,
  payload: { error },
});

export function fetchDayOrders(day) {
  let PTDate = parseFromTimeZone(day, { timeZone: 'US/Pacific' });
  PTDate.setHours(0);
  PTDate.setMinutes(0);
  PTDate.setSeconds(0);
  PTDate.setMilliseconds(0);
  return (dispatch) => {
    dispatch(fetchDayOrdersBegin());
    axiosClient
      .get(`/orders/${PTDate.toISOString()}`)
      .then((result) => {
        dispatch(fetchDayOrdersSuccess(result.data));
        return result.data;
      })
      .catch((error) => dispatch(fetchDayOrdersFailure(error)));
  };
}
