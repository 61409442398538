import axiosClient from '../../utils/API';

export const UPDATE_STATUS_BEGIN = 'UPDATE_STATUS_BEGIN';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';

export const updateStatusBegin = () => ({
  type: UPDATE_STATUS_BEGIN,
});

export const updateStatusSuccess = () => ({
  type: UPDATE_STATUS_SUCCESS,
});

export const updateStatusError = (error) => ({
  type: UPDATE_STATUS_FAILURE,
  payload: { error },
});

export function postUpdateOrderStatus(orderId, status, sendEmail) {
  return (dispatch) => {
    dispatch(updateStatusBegin());
    axiosClient
      .post(`/update-status/${orderId}`, {
        status,
        send_email: sendEmail,
      })
      .then((result) => {
        dispatch(updateStatusSuccess());
        dispatch(result.data.order);
        return result.data;
      })
      .catch((error) => {
        dispatch(updateStatusError(error));
      });
  };
}
