import {
  FETCH_PARTNER_BEGIN,
  FETCH_PARTNER_ERROR,
  FETCH_PARTNER_SUCCESS,
  START_PARTNER_EDIT,
  END_PARTNER_EDIT,
  UPDATE_PARTNER_BEGIN,
  UPDATE_PARTNER_ERROR,
  UPDATE_PARTNER_SUCCESS,
} from './partnerDetailActions';

const initialState = {
  partner: null,
  loading: true,
  error: null,
  editing: false,
};

export default function partnerDetailReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PARTNER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PARTNER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.action,
      };
    case FETCH_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        partner: action.payload.partner,
      };
    case START_PARTNER_EDIT:
      return {
        ...state,
        editing: true,
      };
    case END_PARTNER_EDIT:
      return {
        ...state,
        editing: false,
      };
    case UPDATE_PARTNER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        partner: {
          ...state.partner,
          ...action.payload.updated_partner,
        },
      };
    case UPDATE_PARTNER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
