export const getMilesCost = (input_miles: number, steps: { distance: number; price: number }[]) => {
  let miles = input_miles;
  let total = 0;

  let previous_step_distance = 0;

  for (let step of steps) {
    // entire step used
    if (miles > step.distance - previous_step_distance) {
      miles = miles - (step.distance - previous_step_distance);
      total = total + (step.distance - previous_step_distance) * step.price;

      // entire step not used
    } else {
      total = total + miles * step.price;
      miles = 0;
    }
    previous_step_distance = step.distance;
  }

  return Number(total.toFixed(2));
};
