import React, { useCallback, useEffect, useState } from 'react';

import { FormVariables } from '../types/FormVariables';
import { OfficeLocation } from '../types/Partners';
import { ProductItem } from '../types/ProductItem';
import axiosClient from '../utils/API';
import { handleCatchError } from '../utils/handleCatchError';

type FormContextProps = {
  variablesInContext: FormVariablesInContext | undefined;
  isLoading: boolean;
  fetchVariables: () => void;
  setVariablesInContext: React.Dispatch<React.SetStateAction<FormVariablesInContext | undefined>>;
};

export type FormVariablesInContext = {
  variables: FormVariables[];
  productItems: ProductItem[];
  officeLocations: OfficeLocation[];
};

export const FormVariablesContext = React.createContext<FormContextProps>({} as FormContextProps);

export const FormVariablesContextProvider = ({ children }: any) => {
  const [variablesInContext, setVariablesInContext] = useState<FormVariablesInContext | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const fetchVariables = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosClient.get(`/wizard-variables/all`);

      const {
        data: { variables, productItems, officeLocations },
      } = response;

      if (!variables) {
        throw new Error('An unexpected error has suddenly occurred. Please contact an admin.');
      }

      setVariablesInContext({
        variables: variables.map((i: any) => ({
          createdAt: i.createdAt,
          id: i.id,
          updatedAt: i.updatedAt,
          ...i.variables,
        })),
        productItems,
        officeLocations,
      });
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchVariables();
  }, [fetchVariables]);

  return (
    <FormVariablesContext.Provider
      value={{
        variablesInContext,
        isLoading,
        fetchVariables,
        setVariablesInContext,
      }}
    >
      {children}
    </FormVariablesContext.Provider>
  );
};
