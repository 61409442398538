export const OPEN_PAYMENT_INFO_MODAL = 'OPEN_PAYMENT_INFO_MODAL';
export const CLOSE_PAYMENT_INFO_MODAL = 'CLOSE_PAYMENT_INFO_MODAL';

export const openPaymentInfoModal = () => ({
  type: OPEN_PAYMENT_INFO_MODAL,
});

export const closePaymentInfoModal = () => ({
  type: CLOSE_PAYMENT_INFO_MODAL,
});
