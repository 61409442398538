import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';

import addPaymentInfoReducer from './add-payment-info/addPaymentInfoReducer';
import authReducer from './auth/authReducer';
import changeLocationReducer from './change-location/changeLocationReducer';
import chargeUserReducer from './charge-user/chargeUserReducer';
import dayOrders from './day-orders/dayOrdersReducer';
import loginReducer from './login/loginReducer';
import orderDetailReducer from './order-detail/orderDetailReducer';
import partnerDetailReducer from './partner-detail/partnerDetailReducer';
import partnersReducer from './partners/partnerReducer';
import scheduleReducer from './schedule/scheduleReducer';
import statusReducer from './order-status/statusReducer';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import userDetailReducer from './user-detail/userDetailReducer';

const enhancers = [];
const middleware = [thunk];

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

const migrations = {
  6: (state) => {
    return {
      ...state,
      root: {
        ...state.root,
        dondisp_location: null,
        labor_form_data: {
          total_labor_fees: null,
          hours_estimate: null,
          labor_miles: null,
          labor_time: null,
        },
        dondisp_form_data: {
          total_cost_norange: null,
          pricerange_min: null,
          pricerange_max: null,
          miles: null,
          time: null,
          stairs_elevator_required: null,
          stuff_object: {
            value: null,
            time: null,
          },
          refrigerated_amount: null,
        },
      },
    };
  },
};

const persistConfig = {
  version: 7,
  key: 'root',
  storage,
  blacklist: [
    'login',
    'order_status',
    'order_detail',
    'add_fee',
    'add_deduction',
    'add_user',
    'add-cb',
    'user_detail',
    'labor_time',
    'charge_user',
    'add_payment',
    'change_location',
  ],
  migrate: createMigrate(migrations, { debug: true }),
};

const combinedReducers = combineReducers({
  order_status: statusReducer,
  order_detail: orderDetailReducer,
  user_detail: userDetailReducer,
  login: loginReducer,
  auth: authReducer,
  add_payment: addPaymentInfoReducer,
  partners: partnersReducer,
  schedule: scheduleReducer,
  charge_user: chargeUserReducer,
  partner_detail: partnerDetailReducer,
  change_location: changeLocationReducer,
  dayOrders,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const configureStore = () => {
  let store = createStore(persistedReducer, composedEnhancers);
  let persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
