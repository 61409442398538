import { LocationObject } from '../types/LocationObject';

// This fixes the inconsistent location field type
export const getLocationObjLatLng = (
  locationObj?: LocationObject | null
): Exclude<LocationObject['latLng'], undefined> => {
  return {
    lat: locationObj?.latlng?.lat || locationObj?.latLng?.lat,
    lng: locationObj?.latlng?.lng || locationObj?.latLng?.lng,
  };
};
