import axiosClient from '../../utils/API';

export const FETCH_PARTNER_BEGIN = 'FETCH_PARTNER_BEGIN';
export const FETCH_PARTNER_SUCCESS = 'FETCH_PARTNER_SUCCESS';
export const FETCH_PARTNER_ERROR = 'FETCH_PARTNER_ERROR';

export const START_PARTNER_EDIT = 'START_PARTNER_EDIT';
export const END_PARTNER_EDIT = 'END_PARTNER_EDIT';

export const UPDATE_PARTNER_BEGIN = 'UPDATE_PARTNER_BEGIN';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_ERROR = 'UPDATE_PARTNER_ERROR';

export const fetchPartnerBegin = () => ({
  type: FETCH_PARTNER_BEGIN,
});

export const fetchPartnerSuccess = (partner) => ({
  type: FETCH_PARTNER_SUCCESS,
  payload: { partner },
});

export const fetchPartnerError = (error) => ({
  type: FETCH_PARTNER_ERROR,
  payload: { error },
});

export const startPartnerEdit = () => ({
  type: START_PARTNER_EDIT,
});

export const endPartnerEdit = () => ({
  type: END_PARTNER_EDIT,
});

export const updatePartnerBegin = () => ({
  type: UPDATE_PARTNER_BEGIN,
});

export const updatePartnerSuccess = (updated_partner) => ({
  type: UPDATE_PARTNER_SUCCESS,
  payload: { updated_partner },
});

export const updatePartnerError = (error) => ({
  type: UPDATE_PARTNER_ERROR,
  payload: { error },
});

export function fetchPartnerDetail(partnerId) {
  return (dispatch) => {
    dispatch(fetchPartnerBegin);
    axiosClient
      .get(`/partner/${partnerId}`)
      .then((result) => {
        console.warn(partnerId);
        console.warn(result.data);
        dispatch(fetchPartnerSuccess(result.data));
        return result.data;
      })
      .catch((error) => {
        dispatch(fetchPartnerError(error));
      });
  };
}

export function updatePartner(partnerId, body) {
  return (dispatch) => {
    dispatch(updatePartnerBegin());
    dispatch(endPartnerEdit());
    axiosClient
      .put(`/partner/${partnerId}`, body)
      .then((result) => {
        dispatch(updatePartnerSuccess(result.data));
        return result.data;
      })
      .catch((error) => {
        dispatch(updatePartnerError(error));
      });
  };
}
