import axiosClient from '../../utils/API';

export const FETCH_TIMESLOTS_BEGIN = 'FETCH_TIMESLOTS_BEGIN';
export const FETCH_TIMESLOTS_SUCCESS = 'FETCH_TIMESLOTS_SUCCESS';
export const FETCH_TIMESLOTS_FAILURE = 'FETCH_TIMESLOTS_FAILURE';

export const OPEN_SLOT_MODAL = 'OPEN_SLOT_MODAL';
export const CLOSE_SLOT_MODAL = 'CLOSE_SLOT_MODAL';

export const POST_SLOT_BEGIN = 'POST_SLOT_BEGIN';
export const POST_SLOT_SUCCESS = 'POST_SLOT_SUCCESS';
export const POST_SLOT_FAILURE = 'POST_SLOT_FAILURE';

export const DELETE_SLOT_BEGIN = 'DELETE_SLOT_BEGIN';
export const DELETE_SLOT_SUCCESS = 'DELETE_SLOT_SUCCESS';
export const DELETE_SLOT_FAILURE = 'DELETE_SLOT_FAILURE';

export const START_SLOT_EDIT = 'START_SLOT_EDIT';
export const END_SLOT_EDIT = 'END_SLOT_EDIT';

export const UPDATE_SLOT_BEGIN = 'UPDATE_SLOT_BEGIN';
export const UPDATE_SLOT_SUCCESS = 'UPDATE_SLOT_SUCCESS';
export const UPDATE_SLOT_FAILURE = 'UPDATE_SLOT_FAILURE';

export const fetchTimeslotsBegin = () => ({
  type: FETCH_TIMESLOTS_BEGIN,
});

export const fetchTimeslotsSuccess = (slots) => ({
  type: FETCH_TIMESLOTS_SUCCESS,
  payload: { slots },
});

export const fetchTimeslotsFailure = (error) => ({
  type: FETCH_TIMESLOTS_FAILURE,
  payload: { error },
});

export const postSlotBegin = (slot) => ({
  type: POST_SLOT_BEGIN,
  payload: { slot },
});

export const postSlotSuccess = (slot) => ({
  type: POST_SLOT_SUCCESS,
  payload: { slot },
});

export const postSlotFailure = () => ({
  type: POST_SLOT_FAILURE,
});

export const updateSlotBegin = () => ({
  type: UPDATE_SLOT_BEGIN,
});

export const updateSlotSuccess = () => ({
  type: UPDATE_SLOT_SUCCESS,
});

export const updateSlotFailure = (error) => ({
  type: UPDATE_SLOT_FAILURE,
  payload: { error },
});

export const openSlotModal = (event) => ({
  type: OPEN_SLOT_MODAL,
  payload: { event },
});

export const closeSlotModal = () => ({
  type: CLOSE_SLOT_MODAL,
});

export const deleteSlotBegin = () => ({
  type: DELETE_SLOT_BEGIN,
});

export const deleteSlotSuccess = (slot) => ({
  type: DELETE_SLOT_SUCCESS,
  payload: { slot },
});

export const deleteSlotFailure = (error) => ({
  type: DELETE_SLOT_FAILURE,
  payload: { error },
});

export const startSlotEdit = () => ({
  type: START_SLOT_EDIT,
});

export const endSlotEdit = () => ({
  type: END_SLOT_EDIT,
});

export function fetchTimeslots() {
  return (dispatch) => {
    dispatch(fetchTimeslotsBegin());
    axiosClient
      .get('/slots')
      .then((result) => {
        dispatch(fetchTimeslotsSuccess(result.data));
        return result.data;
      })
      .catch((error) => {
        dispatch(fetchTimeslotsFailure(error));
      });
  };
}

export function createTimeSlot(start, end, title = 'default') {
  return (dispatch) => {
    dispatch(postSlotBegin({ start, end, title }));
    axiosClient
      .post('/slots', {
        title: title,
        start,
        end,
      })
      .then((result) => {
        dispatch(postSlotSuccess(result.data));
        return result.data;
      })
      .catch((error) => {
        dispatch(postSlotFailure(error));
      });
  };
}

export function deleteSlotRemote(id) {
  return (dispatch) => {
    dispatch(deleteSlotBegin());
    axiosClient
      .delete(`/slots/${id}`)
      .then((result) => {
        dispatch(deleteSlotSuccess(result.data));
      })
      .catch((error) => {
        dispatch(deleteSlotFailure(error));
      });
  };
}

export function updateTimeSlot(id, body) {
  return (dispatch) => {
    dispatch(updateSlotBegin());
    axiosClient
      .put(`/slots/${id}`, body)
      .then((result) => {
        dispatch(updateSlotSuccess());
        dispatch(fetchTimeslots());
      })
      .catch((error) => {
        dispatch(updateSlotFailure());
      });
  };
}
