import { FormState } from '../types/Form';

export const generateEmptyItemDeliveryObject = (): Exclude<FormState['item_delivery_form_data'], undefined> => ({
  number_of_items_array: [],
  stairs_yes_no: null,
  elevator_yes_no: null,
  heavy_yes_no: null,
  item_delivery_location: null,
  assembly_yes_no: null,
  assembly_minutes: null,
  per_item_fee: null,
  stairs_fee: null,
  elevator_fee: null,
  assembly_fee: null,
  heavy_fee: null,
  deduction_amount: null,
  fee_total: null,
});
