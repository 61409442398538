import { CLOSE_CHANGE_LOCATION_MODAL, OPEN_CHANGE_LOCATION_MODAL } from './changeLocationActions';

const initialState = {
  loading: false,
  error: null,
  modalOpen: false,
};

export default function changeLocationReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CHANGE_LOCATION_MODAL:
      return {
        ...state,
        modalOpen: true,
      };
    case CLOSE_CHANGE_LOCATION_MODAL:
      return {
        ...state,
        modalOpen: false,
      };
    default:
      return state;
  }
}
