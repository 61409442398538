import { FormState } from '../types/Form';
import { FormVariablesInContext } from '../contexts/FormVariablesContext';
import { LocationObject } from '../types/LocationObject';
import { Order } from '../types/Orders';
import { ServiceNames } from '../types/ServiceTypes';
import { getBlankFormState } from '../contexts/FormContext';
import { getLocationObjLatLng } from './fixLocationObjectType';
import { getServiceFromName } from './getServiceFromName';
import { handleCatchError } from './handleCatchError';

const RequiredLocationsMap: {
  [key in ServiceNames]: (keyof Pick<
    FormState,
    'dropoff_location' | 'pickup_location' | 'labor_location' | 'dondisp_location'
  >)[];
} = {
  'Store Delivery': ['pickup_location', 'dropoff_location'],
  'Craigslist Delivery': ['pickup_location', 'dropoff_location'],
  'Donation/Disposal': ['dondisp_location'],
  'Labor Only': ['labor_location'],
};

const getRoundedMiles = (row: any) => {
  return row.elements[0].distance.value
    ? Number(Math.round(row.elements[0].distance.value / 1609.34).toFixed(2))
    : null;
};

const getRoundedTime = (row: any, multiplier: number) => {
  return row.elements[0].duration.value
    ? Number(Math.round((row.elements[0].duration.value / 3600) * multiplier).toFixed(2))
    : null;
};

export const calculateDrivingDistancesFromOrder = (order: Order, variablesInContext: FormVariablesInContext): Promise<FormState> => {
  const service = getServiceFromName(order.serviceType);



  const variablesForDefaultForm = order.formData?.variablesId ? variablesInContext.variables.find((v) => v.id === order.formData?.variablesId) : variablesInContext.variables?.length ? variablesInContext.variables[0] : null;
  if (!variablesForDefaultForm) throw new Error("Variables not found in context - 4");

  const formState = {
    ...getBlankFormState(variablesForDefaultForm.id),
    ...order,
    ...order.formData,
    service,
  } as FormState;
  console.log('calculateDrivingDistancesFromOrder', formState.office);

  return calculateDrivingDistancesForFormState(formState, variablesInContext);
};

export const calculateDrivingDistancesForFormState = async (
  formState: FormState,
  variablesInContext: FormVariablesInContext
): Promise<FormState> => {
  const { pickup_location, dropoff_location, labor_location, dondisp_location, variablesId } = formState;
  const variables = variablesId ? variablesInContext.variables.find((v) => v.id === variablesId) : variablesInContext.variables?.length ? variablesInContext.variables[0] : null;
  if (!variables) throw new Error("Variables not found in context - 3");
  try {
    // Do checks to see if pickup/dropoff locations are valid for a distance calculation

    if (!formState?.service?.name) return formState;
    const RequiredLocations = RequiredLocationsMap[formState.service.name];

    // if nothing there, then its not needed
    if (RequiredLocations.length === 0) {
      return formState;
    }

    for (const requiredLocation of RequiredLocations) {
      const locationObj: LocationObject = {
        ...formState[requiredLocation],
        latLng: { ...getLocationObjLatLng(formState[requiredLocation]) },
      };
      if (
        !locationObj ||
        !locationObj.valid ||
        !locationObj.latLng ||
        !locationObj.latLng.lat ||
        !locationObj.latLng.lng ||
        !locationObj.address ||
        !locationObj.placeId
      ) {
        // one of the required object properties is missing so we just exit here.
        // reset the distances to nothing to prevent extreneous cost errors
        return {
          ...formState,
          pickup_to_dropoff_miles: null,
          office_to_pickup_miles: null,
          pickup_to_dropoff_hours: null,
          office_to_pickup_hours: null,
          labor_form_data: {
            ...formState.labor_form_data,
            labor_miles: null,
            hours_estimate: null,
          },
          dondisp_form_data: {
            ...formState.dondisp_form_data,
            miles: null,
            time: null,
          },
        };
      }
    }

    const googleMapsDistanceAPI = new window.google.maps.DistanceMatrixService();
    const destinationLocation = RequiredLocations.includes('pickup_location')
      ? new window.google.maps.LatLng(
        getLocationObjLatLng(pickup_location)?.lat!,
        getLocationObjLatLng(pickup_location)?.lng
      )
      : RequiredLocations.includes('labor_location')
        ? new window.google.maps.LatLng(
          getLocationObjLatLng(labor_location)?.lat!,
          getLocationObjLatLng(labor_location)?.lng
        )
        : RequiredLocations.includes('dondisp_location')
          ? new window.google.maps.LatLng(
            getLocationObjLatLng(dondisp_location)?.lat!,
            getLocationObjLatLng(dondisp_location)?.lng
          )
          : null;

    if (!destinationLocation) return formState;

    const newFormState = { ...formState };

    const getDistance = async (originLocation: { placeId: string }) => {
      const origins: google.maps.DistanceMatrixRequest['origins'] = [originLocation];
      // add dropoff location to origin if its a required location
      if (RequiredLocations.includes('dropoff_location')) {
        origins.push(
          new window.google.maps.LatLng(
            getLocationObjLatLng(dropoff_location)?.lat!,
            getLocationObjLatLng(dropoff_location)?.lng!
          )
        );
      }

      return googleMapsDistanceAPI.getDistanceMatrix({
        origins,
        destinations: [destinationLocation],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      });
    };

    const getClosestOffice = async () => {
      const results = await Promise.all(
        variablesInContext.officeLocations.map((officeLocation) => getDistance({ placeId: officeLocation.placeId }))
      );

      const closestResult = results.reduce((prev, curr) => {
        const prevMiles = getRoundedMiles(prev.rows[0]);
        const currMiles = getRoundedMiles(curr.rows[0]);

        if (!prevMiles) return curr;
        if (!currMiles) return prev;

        return prevMiles < currMiles ? prev : curr;
      });

      const indexOfResult = results.indexOf(closestResult);

      newFormState.office = variablesInContext.officeLocations[indexOfResult];

      return closestResult;
    };

    const getOfficeDistance = async () => {
      if (formState.office) {
        return await getDistance({ placeId: formState.office.placeId });
      }
      return await getClosestOffice();
    };

    const distanceResponse = await getOfficeDistance();

    if (formState.service.name === 'Store Delivery' || formState.service.name === 'Craigslist Delivery') {
      const milesOfficeToPickup = getRoundedMiles(distanceResponse.rows[0]);
      const milesPickupToDropoff = getRoundedMiles(distanceResponse.rows[1]);
      const officeToPickupTimeInHours = getRoundedTime(
        distanceResponse.rows[0],
        variables[formState.service.name].deliveryTimeMultiplier
      );
      const pickupToDropoffTimeInHours = getRoundedTime(
        distanceResponse.rows[1],
        variables[formState.service.name].deliveryTimeMultiplier
      );

      newFormState.pickup_to_dropoff_miles = milesPickupToDropoff;
      newFormState.office_to_pickup_miles = milesOfficeToPickup;
      newFormState.pickup_to_dropoff_hours = pickupToDropoffTimeInHours;
      newFormState.office_to_pickup_hours = officeToPickupTimeInHours;
    }

    if (formState.service.name === 'Donation/Disposal') {
      const milesOfficeToAddress = getRoundedMiles(distanceResponse.rows[0]);
      if ("version" in variables && variables.version === '2') {
        throw new Error('Not implemented');
      }
      const timeInHours = getRoundedTime(
        distanceResponse.rows[0],
        // TS ignore
        (variables as any)[formState.service.name].deliveryTimeMultiplier
      );
      newFormState.dondisp_form_data.miles = milesOfficeToAddress;
      newFormState.dondisp_form_data.time = timeInHours;
    }

    if (formState.service.name === 'Labor Only') {
      const milesOfficeToAddress = getRoundedMiles(distanceResponse.rows[0]);
      const timeInHours = getRoundedTime(
        distanceResponse.rows[0],
        variables[formState.service.name].deliveryTimeMultiplier
      );
      newFormState.labor_form_data.labor_miles = milesOfficeToAddress;
      newFormState.labor_form_data.hours_estimate = timeInHours;
    }

    return newFormState;
  } catch (err) {
    handleCatchError(err);
    return formState;
  }
};
