import axiosClient from '../../utils/API';

export const FETCH_PARTNERS_BEGIN = 'FETCH_PARTNERS_BEGIN';
export const FETCH_PARTNERS_FAILURE = 'FETCH_PARTNERS_FAILURE';
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS';

export const fetchPartnersBegin = () => ({
  type: FETCH_PARTNERS_BEGIN,
});

export const fetchPartnersSuccess = (partners) => ({
  type: FETCH_PARTNERS_SUCCESS,
  payload: { partners },
});

export const fetchPartnersFailure = (error) => ({
  type: FETCH_PARTNERS_FAILURE,
  payload: { error },
});

export function fetchPartners() {
  return (dispatch) => {
    dispatch(fetchPartnersBegin());
    axiosClient
      .get('/partner')
      .then((result) => {
        dispatch(fetchPartnersSuccess(result.data));
        return result.data;
      })
      .catch((error) => dispatch(fetchPartnersFailure(error)));
  };
}
