import {
  ADD_EDITED_FIELD_VALUE,
  ADD_TO_TOTAL,
  ORDER_CHARGE_BEGIN,
  ORDER_CHARGE_ERROR,
  ORDER_CHARGE_SUCCESS,
  ORDER_DELETE_BEGIN,
  ORDER_DELETE_ERROR,
  ORDER_DELETE_SUCCESS,
  POST_EDITED_ORDER_BEGIN,
  POST_EDITED_ORDER_ERROR,
  POST_EDITED_ORDER_SUCCESS,
  SUBTRACT_FROM_TOTAL,
} from './orderDetailActions';

const initialState = {
  order: null,
  loading: true,
  error: null,
  editing: false,
  edited_order: null,
  order_deleted: false,
  item_delivery_editing: false,
  charge_loading: false,
  deliver_location_editing: false,
};

export default function orderDetailReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_EDITED_FIELD_VALUE:
      return {
        ...state,
        edited_order: {
          [action.payload.field]: action.payload.value,
        },
      };
    case POST_EDITED_ORDER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        edited_order: null,
        editing: false,
        item_delivery_editing: false,
      };
    case POST_EDITED_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: {
          ...state.order,
          ...action.payload.order,
        },
      };
    case POST_EDITED_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ORDER_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ORDER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ORDER_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case ADD_TO_TOTAL:
      return {
        ...state,
        order: {
          ...state.order,
          total: state.order.total + action.payload.amount,
        },
      };
    case SUBTRACT_FROM_TOTAL:
      return {
        ...state,
        order: {
          ...state.order,
          total: state.order.total - action.payload.amount,
        },
      };
    case ORDER_CHARGE_BEGIN:
      return {
        ...state,
        charge_loading: true,
      };
    case ORDER_CHARGE_SUCCESS:
      return {
        ...state,
        charge_loading: false,

        order: {
          ...state.order,
          charged: true,
          status: 'COMPLETED',
        },
      };
    case ORDER_CHARGE_ERROR:
      return {
        ...state,
        charge_loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
