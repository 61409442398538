import { FormVariablesV1, FormVariablesV2 } from '../types/FormVariables';

import { FormState } from '../types/Form';
import { FormVariablesInContext } from '../contexts/FormVariablesContext';
import { Order } from '../types/Orders';
import { ServiceNames } from '../types/ServiceTypes';
import { generateEmptyItemDeliveryObject } from './generateEmptyFormObjects';
import { getBlankFormState } from '../contexts/FormContext';
import { getMilesCost } from './calcMilesCost';
import { getServiceFromName } from './getServiceFromName';

export type InfoTable = { [key: string]: { amount: number; description: string } };

export const computeCostsAndHoursFromOrder = (
  order: Order,
  variablesInContext: FormVariablesInContext
): { formState: FormState; costTable: InfoTable; timeTable: InfoTable } => {
  const service = getServiceFromName(order.serviceType);

  const variablesToUse = order.formData?.variablesId
    ? variablesInContext.variables.find((v) => v.id === order.formData?.variablesId)
    : variablesInContext.variables?.length
      ? variablesInContext.variables[0]
      : null;

  if (!variablesToUse) throw new Error('Variables not found in context - 9');

  const formState = {
    ...getBlankFormState(variablesToUse.id),
    ...order,
    ...order.formData,
    service,
  } as FormState;

  return computeCostsAndHours(formState, variablesInContext);
};

export const computeCostsAndHours = (
  formState: FormState,
  variablesInContext: FormVariablesInContext
): { formState: FormState; costTable: InfoTable; timeTable: InfoTable } => {
  const newFormState: FormState = { ...formState };

  const variablesToUse = formState?.variablesId
    ? variablesInContext.variables.find((v) => v.id === formState?.variablesId)
    : variablesInContext.variables?.length
      ? variablesInContext.variables[0]
      : null;

  if (!variablesToUse) throw new Error('Variables not found in context - 10');


  const costTable: InfoTable = {};
  const timeTable: InfoTable = {};

  const addCostToConsoleTable = (title: string, amount: number, description: string) => {
    costTable[title] = {
      amount,
      description,
    };
  };

  const addTimeToConsoleTable = (title: string, amount: number, description: string) => {
    timeTable[title] = {
      amount,
      description,
    };
  };

  // silently quit.
  if (!newFormState.service) return { formState: newFormState, costTable, timeTable };

  // reset totals
  newFormState.estimated_cost = 0;
  newFormState.hours_estimate = 0;



  // const typedVariables = isVariablesV2(variables) ? (variables as FormVariablesV1) : (variables as FormVariablesV2);

  if ('version' in variablesToUse && variablesToUse.version === '2') {
    const variables = variablesToUse as FormVariablesV2;

    if (newFormState.service.name === 'Donation/Disposal') {
      throw new Error('Not implemented - Donation Disposal Service Removed');
    }
    // VERSION 2
    // add base fee
    newFormState.estimated_cost += variables[newFormState.service.name].baseFee;
    addCostToConsoleTable('Base Fee', variables[newFormState.service.name].baseFee, newFormState.service.name);
    newFormState.hours_estimate += variables[newFormState.service.name].baseHours;
    addTimeToConsoleTable('Base Time', variables[newFormState.service.name].baseHours, newFormState.service.name);

    // Add office to pickup mileage cost (distance fee)
    // This map just makes things a little bit DRYER. The data structure was NOT consistent.
    const serviceNameToMileKeyMap: { [key in ServiceNames]: string } = {
      'Craigslist Delivery': 'office_to_pickup_miles',
      'Store Delivery': 'office_to_pickup_miles',
      'Donation/Disposal': 'dondisp_form_data.miles',
      'Labor Only': 'labor_form_data.labor_miles',
    };
    const keyOfMileageFromOffice = serviceNameToMileKeyMap[newFormState.service.name] as keyof FormState;

    // this hacky looking statement allows deep accessor strings to work
    const milesFromOfficeToLocation = Number(
      keyOfMileageFromOffice.split('.').reduce((p: any, c) => (p && p[c]) || null, newFormState)
    );
    if (!isNaN(milesFromOfficeToLocation)) {
      const officeToLocationFee =
        milesFromOfficeToLocation * variables[newFormState.service.name].costPerMileOfficeToLocation;
      newFormState.estimated_cost += officeToLocationFee;
      addCostToConsoleTable(
        'Office To Location Fee',
        officeToLocationFee,
        `${milesFromOfficeToLocation} miles * $${variables[newFormState.service.name].costPerMileOfficeToLocation}/mile`
      );
    }


    if (newFormState.service.name === 'Labor Only') {
      // add the labor time fee
      newFormState.labor_form_data.labor_time.fee =
        (Number(newFormState.labor_form_data.labor_time.time) || 0) * variables[newFormState.service.name].costPerMinuteForLabor;
      newFormState.estimated_cost += newFormState.labor_form_data.labor_time.fee || 0;
      addCostToConsoleTable(
        'Labor Fee',
        newFormState.labor_form_data.labor_time.fee || 0,
        `${newFormState.labor_form_data.labor_time.time} @ $${variables[newFormState.service.name].costPerMinuteForLabor}/minute`
      );

      // add office to location time estimate
      newFormState.hours_estimate += newFormState.labor_form_data.hours_estimate || 0;
      addTimeToConsoleTable(
        'Office To Location',
        newFormState.labor_form_data.hours_estimate || 0,
        `${newFormState.labor_form_data.labor_miles} miles`
      );
      // add labor time estimate
      const laborTimeEstimateInHours = newFormState.labor_form_data.labor_time.time
        ? Number((Number(newFormState.labor_form_data.labor_time.time) / 60).toFixed(2))
        : 0;
      newFormState.hours_estimate += laborTimeEstimateInHours;
      addTimeToConsoleTable('Labor Time', laborTimeEstimateInHours, `${laborTimeEstimateInHours} hours`);
    }

    if (newFormState.service.name === 'Store Delivery' || newFormState.service.name === 'Craigslist Delivery') {
      // Stepped mileage cost
      const steppedMileageFee = getMilesCost(newFormState.pickup_to_dropoff_miles || 0, variables[newFormState.service.name].steppedMileageCost);
      newFormState.estimated_cost += steppedMileageFee;
      addCostToConsoleTable('Stepped Mileage Fee', steppedMileageFee, ``);

      // hours estimate
      newFormState.hours_estimate +=
        (newFormState.pickup_to_dropoff_hours || 0) + (newFormState.office_to_pickup_hours || 0);
      addTimeToConsoleTable(
        'Drive Time',
        (newFormState.pickup_to_dropoff_hours || 0) + (newFormState.office_to_pickup_hours || 0),
        `${newFormState.pickup_to_dropoff_hours || 0} pickup-to-dropoff & ${newFormState.office_to_pickup_hours || 0
        } office-to-pickup`
      );

      // Item information Calculation
      const numberOfItems = newFormState.item_delivery_form_data.number_of_items_array.length;
      const hasStairs = newFormState.item_delivery_form_data.stairs_yes_no === 'yes';
      const hasElevator = newFormState.item_delivery_form_data.elevator_yes_no === 'yes';
      const deductionForItemDeliveryLocation =
        newFormState.item_delivery_form_data.item_delivery_location === 'curbside' ||
        newFormState.item_delivery_form_data.item_delivery_location === 'garage';
      const hasAssembly = newFormState.item_delivery_form_data.assembly_yes_no === 'yes';

      // if assembly question answered to no but we somehow have assembly minutes, set it back to null
      if (!hasAssembly && newFormState.item_delivery_form_data.assembly_minutes) {
        newFormState.item_delivery_form_data.assembly_minutes = null;
      }
      const isHeavy = newFormState.item_delivery_form_data.heavy_yes_no === 'yes';

      const perItemFee = numberOfItems * variables[newFormState.service.name].baseFeePerItem;
      newFormState.item_delivery_form_data.per_item_fee = perItemFee;
      addCostToConsoleTable('Per Item Fee', perItemFee, `${numberOfItems} items * $${variables[newFormState.service.name].baseFeePerItem}/item`);

      const perItemTimeEstimate = Number(((numberOfItems * variables[newFormState.service.name].perItemTimeEstimate) / 60).toFixed(2));
      newFormState.hours_estimate += perItemTimeEstimate;
      addTimeToConsoleTable('Per Item Time', perItemTimeEstimate, `${variables[newFormState.service.name].perItemTimeEstimate} minutes per item`);

      const stairsFee = hasStairs ? numberOfItems * variables[newFormState.service.name].stairsFeePerItem : 0;
      newFormState.item_delivery_form_data.stairs_fee = stairsFee;
      addCostToConsoleTable(
        'Stairs Fee',
        stairsFee,
        hasStairs ? `${numberOfItems} items * $${variables[newFormState.service.name].stairsFeePerItem}/item` : 'No Stairs'
      );

      const perItemBonusTimeForStairs = Number(
        (hasStairs ? (numberOfItems * variables[newFormState.service.name].perItemBonusTimeForStairs) / 60 : 0).toFixed(2)
      );
      newFormState.hours_estimate += perItemBonusTimeForStairs;
      addTimeToConsoleTable(
        'Stairs Bonus Time',
        perItemBonusTimeForStairs,
        `${variables[newFormState.service.name].perItemBonusTimeForStairs} minutes per item`
      );

      const elevatorFee = hasElevator ? numberOfItems * variables[newFormState.service.name].elevatorFeePerItem : 0;
      newFormState.item_delivery_form_data.elevator_fee = elevatorFee;
      addCostToConsoleTable(
        'Elevator Fee',
        elevatorFee,
        hasElevator ? `${numberOfItems} items * $${variables[newFormState.service.name].elevatorFeePerItem}/item` : 'No Elevator'
      );

      const perItemBonusTimeForElevator = Number(
        (hasElevator ? (numberOfItems * variables[newFormState.service.name].perItemBonusTimeForElevator) / 60 : 0).toFixed(2)
      );
      newFormState.hours_estimate += perItemBonusTimeForElevator;
      addTimeToConsoleTable(
        'Elevator Bonus Time',
        perItemBonusTimeForElevator,
        `${variables[newFormState.service.name].perItemBonusTimeForElevator} minutes per item`
      );

      const assemblyFee = hasAssembly
        ? (newFormState.item_delivery_form_data.assembly_minutes || 0) * variables[newFormState.service.name].costPerMinuteForLabor
        : 0;
      newFormState.item_delivery_form_data.assembly_fee = assemblyFee;
      addCostToConsoleTable(
        'Assembly Fee',
        assemblyFee,
        hasAssembly && newFormState.item_delivery_form_data.assembly_minutes
          ? `${newFormState.item_delivery_form_data.assembly_minutes} minutes * $${variables[newFormState.service.name].costPerMinuteForLabor}/minute`
          : 'No Assembly'
      );

      const assemblyTimeEstimate = newFormState.item_delivery_form_data.assembly_minutes
        ? Number((newFormState.item_delivery_form_data.assembly_minutes / 60).toFixed(2))
        : 0;
      newFormState.hours_estimate += assemblyTimeEstimate;
      addTimeToConsoleTable('Assembly Time', assemblyTimeEstimate, ``);

      const heavyFee = isHeavy ? variables[newFormState.service.name].heavyItemFee : 0;
      newFormState.item_delivery_form_data.heavy_fee = heavyFee;
      addCostToConsoleTable('Heavy Items Fee', heavyFee, '');

      const deductionAmount = deductionForItemDeliveryLocation
        ? numberOfItems * variables[newFormState.service.name].deductionPerItemForItemDeliveryLocation
        : 0;
      newFormState.item_delivery_form_data.deduction_amount = deductionAmount;
      addCostToConsoleTable(
        'Deduction For Delivery Location',
        deductionAmount,
        deductionForItemDeliveryLocation
          ? `[${newFormState.item_delivery_form_data.item_delivery_location}] ${numberOfItems} items * -$${variables[newFormState.service.name].deductionPerItemForItemDeliveryLocation * -1
          }/item`
          : String(newFormState.item_delivery_form_data.item_delivery_location)
      );

      const totalItemFee = perItemFee + stairsFee + elevatorFee + heavyFee + assemblyFee + deductionAmount;
      newFormState.item_delivery_form_data.fee_total = totalItemFee;

      // add cost to total estimate
      newFormState.estimated_cost += totalItemFee;
    } else {
      // force to set the item delivery form as empty when we arent using it... doing this to ensure im not causing breaking changes somehow from this JSON blob type.
      newFormState.item_delivery_form_data = generateEmptyItemDeliveryObject();
    }

    //ensure estimates are set to fixed floats
    newFormState.estimated_cost = Number(newFormState.estimated_cost.toFixed(2));
    newFormState.hours_estimate = Number(newFormState.hours_estimate.toFixed(2));

    console.log(
      '%c Cost Estimate ',
      'color: rgba(25,25,25); font-size: 20px; font-weight: 700px; background: rgb(156, 255, 144);'
    );
    // if service is donation disposal show the range and set estimated cost to the middle of the range

    addCostToConsoleTable('Total', newFormState.estimated_cost, '');

    console.table(costTable);
    console.log('----------------');
    console.log(
      '%c Time Estimate ',
      'color: rgba(25,25,25); font-size: 20px; font-weight: 700px; background: rgb(156, 255, 144);'
    );
    addTimeToConsoleTable('Total', newFormState.hours_estimate, '');
    console.table(timeTable);
  } else {
    // VERSION 1
    const variables = variablesToUse as FormVariablesV1;
    // add base fee
    newFormState.estimated_cost += variables[newFormState.service.name].baseFee;
    addCostToConsoleTable('Base Fee', variables[newFormState.service.name].baseFee, newFormState.service.name);
    newFormState.hours_estimate += variables[newFormState.service.name].baseHours;
    addTimeToConsoleTable('Base Time', variables[newFormState.service.name].baseHours, newFormState.service.name);

    // Add office to pickup mileage cost (distance fee)
    // This map just makes things a little bit DRYER. The data structure was NOT consistent.
    const serviceNameToMileKeyMap: { [key in ServiceNames]: string } = {
      'Craigslist Delivery': 'office_to_pickup_miles',
      'Store Delivery': 'office_to_pickup_miles',
      'Donation/Disposal': 'dondisp_form_data.miles',
      'Labor Only': 'labor_form_data.labor_miles',
    };
    const keyOfMileageFromOffice = serviceNameToMileKeyMap[newFormState.service.name] as keyof FormState;

    // this hacky looking statement allows deep accessor strings to work
    const milesFromOfficeToLocation = Number(
      keyOfMileageFromOffice.split('.').reduce((p: any, c) => (p && p[c]) || null, newFormState)
    );
    if (!isNaN(milesFromOfficeToLocation)) {
      const officeToLocationFee =
        milesFromOfficeToLocation * variables[newFormState.service.name].costPerMileOfficeToLocation;
      newFormState.estimated_cost += officeToLocationFee;
      addCostToConsoleTable(
        'Office To Location Fee',
        officeToLocationFee,
        `${milesFromOfficeToLocation} miles * $${variables[newFormState.service.name].costPerMileOfficeToLocation}/mile`
      );
    }

    if (newFormState.service.name === 'Donation/Disposal') {
      // first set the relevant option stuff
      const matchingStuffObject = variables.donationDisposalStuffOptions.find(
        (i) => i.title === newFormState.dondisp_form_data.stuff_object.value
      );
      if (matchingStuffObject) {
        newFormState.dondisp_form_data.pricerange_min = matchingStuffObject.min;
        newFormState.dondisp_form_data.pricerange_max = matchingStuffObject.max;
        newFormState.dondisp_form_data.stuff_object.time = matchingStuffObject.optionTime;
      }

      // add office to location time estimate
      const stairsOrElevatorTimeMultiplier =
        newFormState.dondisp_form_data.stairs_elevator_required === 'stairs'
          ? variables.donationDisposalStairsTimeMultiplier
          : newFormState.dondisp_form_data.stairs_elevator_required === 'elevator'
            ? variables.donationDisposalElevatorTimeMultiplier
            : 1;
      const timeEstimateWithStairsOrElevatorMultiplier = Number(
        (((newFormState.dondisp_form_data.stuff_object.time || 0) * stairsOrElevatorTimeMultiplier) / 60).toFixed(2)
      );
      newFormState.hours_estimate += timeEstimateWithStairsOrElevatorMultiplier;
      addTimeToConsoleTable(
        'Time Estimate',
        timeEstimateWithStairsOrElevatorMultiplier,
        `${newFormState.dondisp_form_data.stuff_object.time ? newFormState.dondisp_form_data.stuff_object.time / 60 : 0
        } hours * ${stairsOrElevatorTimeMultiplier} (multiplier for ${newFormState.dondisp_form_data.stairs_elevator_required
        })`
      );

      // add refrigerated fee
      const refrigerationFee = (newFormState.dondisp_form_data.refrigerated_amount || 0) * variables.refrigeratedItemFee;
      newFormState.estimated_cost += refrigerationFee;
      addCostToConsoleTable(
        'Refrigeration Fee',
        refrigerationFee,
        `${newFormState.dondisp_form_data.refrigerated_amount || 0} * $${variables.refrigeratedItemFee}/item`
      );

      // add office to location cost estimate
      const stairsOrElevatorCostMultiplier =
        newFormState.dondisp_form_data.stairs_elevator_required === 'stairs'
          ? variables.donationDisposalStairsPriceMultiplier
          : newFormState.dondisp_form_data.stairs_elevator_required === 'elevator'
            ? variables.donationDisposalElevatorPriceMultiplier
            : 1;

      const costEstimateWithStairsOrElevatorMultiplier = Number(
        (newFormState.estimated_cost * (stairsOrElevatorCostMultiplier - 1)).toFixed(2)
      );
      newFormState.estimated_cost += costEstimateWithStairsOrElevatorMultiplier;
      addCostToConsoleTable(
        'Stairs Or Elevator Fee',
        costEstimateWithStairsOrElevatorMultiplier,
        `Multiplier = ${stairsOrElevatorCostMultiplier}`
      );

      // HIDDEN IN HERE BUT THE MIN/MAX VALUES ARE NOT IN THE FORM
      addCostToConsoleTable(
        'Min Fee',
        newFormState.dondisp_form_data.pricerange_min || 0,
        `${newFormState.dondisp_form_data.stuff_object.value}`
      );
      addCostToConsoleTable(
        'Max Fee',
        newFormState.dondisp_form_data.pricerange_max || 0,
        `${newFormState.dondisp_form_data.stuff_object.value}`
      );
    }

    if (newFormState.service.name === 'Labor Only') {
      // add the labor time fee
      newFormState.labor_form_data.labor_time.fee =
        (Number(newFormState.labor_form_data.labor_time.time) || 0) * variables.costPerMinuteForLabor;
      newFormState.estimated_cost += newFormState.labor_form_data.labor_time.fee || 0;
      addCostToConsoleTable(
        'Labor Fee',
        newFormState.labor_form_data.labor_time.fee || 0,
        `${newFormState.labor_form_data.labor_time.time} @ $${variables.costPerMinuteForLabor}/minute`
      );

      // add office to location time estimate
      newFormState.hours_estimate += newFormState.labor_form_data.hours_estimate || 0;
      addTimeToConsoleTable(
        'Office To Location',
        newFormState.labor_form_data.hours_estimate || 0,
        `${newFormState.labor_form_data.labor_miles} miles`
      );
      // add labor time estimate
      const laborTimeEstimateInHours = newFormState.labor_form_data.labor_time.time
        ? Number((Number(newFormState.labor_form_data.labor_time.time) / 60).toFixed(2))
        : 0;
      newFormState.hours_estimate += laborTimeEstimateInHours;
      addTimeToConsoleTable('Labor Time', laborTimeEstimateInHours, `${laborTimeEstimateInHours} hours`);
    }

    if (newFormState.service.name === 'Store Delivery' || newFormState.service.name === 'Craigslist Delivery') {
      // Stepped mileage cost
      const steppedMileageFee = getMilesCost(newFormState.pickup_to_dropoff_miles || 0, variables.steppedMileageCost);
      newFormState.estimated_cost += steppedMileageFee;
      addCostToConsoleTable('Stepped Mileage Fee', steppedMileageFee, ``);

      // hours estimate
      newFormState.hours_estimate +=
        (newFormState.pickup_to_dropoff_hours || 0) + (newFormState.office_to_pickup_hours || 0);
      addTimeToConsoleTable(
        'Drive Time',
        (newFormState.pickup_to_dropoff_hours || 0) + (newFormState.office_to_pickup_hours || 0),
        `${newFormState.pickup_to_dropoff_hours || 0} pickup-to-dropoff & ${newFormState.office_to_pickup_hours || 0
        } office-to-pickup`
      );

      // Item information Calculation
      const numberOfItems = newFormState.item_delivery_form_data.number_of_items_array.length;
      const hasStairs = newFormState.item_delivery_form_data.stairs_yes_no === 'yes';
      const hasElevator = newFormState.item_delivery_form_data.elevator_yes_no === 'yes';
      const deductionForItemDeliveryLocation =
        newFormState.item_delivery_form_data.item_delivery_location === 'curbside' ||
        newFormState.item_delivery_form_data.item_delivery_location === 'garage';
      const hasAssembly = newFormState.item_delivery_form_data.assembly_yes_no === 'yes';

      // if assembly question answered to no but we somehow have assembly minutes, set it back to null
      if (!hasAssembly && newFormState.item_delivery_form_data.assembly_minutes) {
        newFormState.item_delivery_form_data.assembly_minutes = null;
      }
      const isHeavy = newFormState.item_delivery_form_data.heavy_yes_no === 'yes';

      const perItemFee = numberOfItems * variables.baseFeePerItem;
      newFormState.item_delivery_form_data.per_item_fee = perItemFee;
      addCostToConsoleTable('Per Item Fee', perItemFee, `${numberOfItems} items * $${variables.baseFeePerItem}/item`);

      const perItemTimeEstimate = Number(((numberOfItems * variables.perItemTimeEstimate) / 60).toFixed(2));
      newFormState.hours_estimate += perItemTimeEstimate;
      addTimeToConsoleTable('Per Item Time', perItemTimeEstimate, `${variables.perItemTimeEstimate} minutes per item`);

      const stairsFee = hasStairs ? numberOfItems * variables.stairsFeePerItem : 0;
      newFormState.item_delivery_form_data.stairs_fee = stairsFee;
      addCostToConsoleTable(
        'Stairs Fee',
        stairsFee,
        hasStairs ? `${numberOfItems} items * $${variables.stairsFeePerItem}/item` : 'No Stairs'
      );

      const perItemBonusTimeForStairs = Number(
        (hasStairs ? (numberOfItems * variables.perItemBonusTimeForStairs) / 60 : 0).toFixed(2)
      );
      newFormState.hours_estimate += perItemBonusTimeForStairs;
      addTimeToConsoleTable(
        'Stairs Bonus Time',
        perItemBonusTimeForStairs,
        `${variables.perItemBonusTimeForStairs} minutes per item`
      );

      const elevatorFee = hasElevator ? numberOfItems * variables.elevatorFeePerItem : 0;
      newFormState.item_delivery_form_data.elevator_fee = elevatorFee;
      addCostToConsoleTable(
        'Elevator Fee',
        elevatorFee,
        hasElevator ? `${numberOfItems} items * $${variables.elevatorFeePerItem}/item` : 'No Elevator'
      );

      const perItemBonusTimeForElevator = Number(
        (hasElevator ? (numberOfItems * variables.perItemBonusTimeForElevator) / 60 : 0).toFixed(2)
      );
      newFormState.hours_estimate += perItemBonusTimeForElevator;
      addTimeToConsoleTable(
        'Elevator Bonus Time',
        perItemBonusTimeForElevator,
        `${variables.perItemBonusTimeForElevator} minutes per item`
      );

      const assemblyFee = hasAssembly
        ? (newFormState.item_delivery_form_data.assembly_minutes || 0) * variables.costPerMinuteForLabor
        : 0;
      newFormState.item_delivery_form_data.assembly_fee = assemblyFee;
      addCostToConsoleTable(
        'Assembly Fee',
        assemblyFee,
        hasAssembly && newFormState.item_delivery_form_data.assembly_minutes
          ? `${newFormState.item_delivery_form_data.assembly_minutes} minutes * $${variables.costPerMinuteForLabor}/minute`
          : 'No Assembly'
      );

      const assemblyTimeEstimate = newFormState.item_delivery_form_data.assembly_minutes
        ? Number((newFormState.item_delivery_form_data.assembly_minutes / 60).toFixed(2))
        : 0;
      newFormState.hours_estimate += assemblyTimeEstimate;
      addTimeToConsoleTable('Assembly Time', assemblyTimeEstimate, ``);

      const heavyFee = isHeavy ? variables.heavyItemFee : 0;
      newFormState.item_delivery_form_data.heavy_fee = heavyFee;
      addCostToConsoleTable('Heavy Items Fee', heavyFee, '');

      const deductionAmount = deductionForItemDeliveryLocation
        ? numberOfItems * variables.deductionPerItemForItemDeliveryLocation
        : 0;
      newFormState.item_delivery_form_data.deduction_amount = deductionAmount;
      addCostToConsoleTable(
        'Deduction For Delivery Location',
        deductionAmount,
        deductionForItemDeliveryLocation
          ? `[${newFormState.item_delivery_form_data.item_delivery_location}] ${numberOfItems} items * -$${variables.deductionPerItemForItemDeliveryLocation * -1
          }/item`
          : String(newFormState.item_delivery_form_data.item_delivery_location)
      );

      const totalItemFee = perItemFee + stairsFee + elevatorFee + heavyFee + assemblyFee + deductionAmount;
      newFormState.item_delivery_form_data.fee_total = totalItemFee;

      // add cost to total estimate
      newFormState.estimated_cost += totalItemFee;
    } else {
      // force to set the item delivery form as empty when we arent using it... doing this to ensure im not causing breaking changes somehow from this JSON blob type.
      newFormState.item_delivery_form_data = generateEmptyItemDeliveryObject();
    }

    //ensure estimates are set to fixed floats
    newFormState.estimated_cost = Number(newFormState.estimated_cost.toFixed(2));
    newFormState.hours_estimate = Number(newFormState.hours_estimate.toFixed(2));

    console.log(
      '%c Cost Estimate ',
      'color: rgba(25,25,25); font-size: 20px; font-weight: 700px; background: rgb(156, 255, 144);'
    );
    // if service is donation disposal show the range and set estimated cost to the middle of the range
    if (newFormState.service.name === 'Donation/Disposal') {
      addCostToConsoleTable('Base Total', newFormState.estimated_cost, '');
      addCostToConsoleTable(
        'Min Total given to customer',
        newFormState.estimated_cost + (newFormState.dondisp_form_data.pricerange_min || 0),
        ''
      );
      addCostToConsoleTable(
        'Max Total given to customer',
        newFormState.estimated_cost + (newFormState.dondisp_form_data.pricerange_max || 0),
        ''
      );
    } else {
      addCostToConsoleTable('Total', newFormState.estimated_cost, '');
    }

    console.table(costTable);
    console.log('----------------');
    console.log(
      '%c Time Estimate ',
      'color: rgba(25,25,25); font-size: 20px; font-weight: 700px; background: rgb(156, 255, 144);'
    );
    addTimeToConsoleTable('Total', newFormState.hours_estimate, '');
    console.table(timeTable);
  }


  return { formState: newFormState, costTable, timeTable };
};
