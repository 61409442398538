import axiosClient from '../../utils/API';

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

export const START_USER_EDIT = 'START_USER_EDIT';
export const END_USER_EDIT = 'END_USER_EDIT';

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const START_CHANGE_PASSWORD = 'START_CHANGE_PASSWORD';
export const END_CHANGE_PASSWORD = 'END_CHANGE_PASSWORD';

export const fetchUserBegin = () => ({
  type: FETCH_USER_BEGIN,
});

export const fetchUserError = (error) => ({
  type: FETCH_USER_ERROR,
  payload: { error },
});

export const fetchUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: { user },
});

export const startUserEdit = () => ({
  type: START_USER_EDIT,
});

export const endUserEdit = () => ({
  type: END_USER_EDIT,
});

export const startChangePassword = () => ({
  type: START_CHANGE_PASSWORD,
});

export const endChangePassword = () => ({
  type: END_CHANGE_PASSWORD,
});

export const updateUserBegin = () => ({
  type: UPDATE_USER_BEGIN,
});

export const updateUserSuccess = (updated_user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { updated_user },
});

export const updateUserError = (error) => ({
  type: UPDATE_USER_ERROR,
  payload: { error },
});

export function fetchUserDetail(userId) {
  return (dispatch) => {
    dispatch(fetchUserBegin());
    axiosClient
      .get(`/users/${userId}`)
      .then((result) => {
        dispatch(fetchUserSuccess(result.data));
        return result.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchUserError(error));
      });
  };
}

export function updateUser(userId, body) {
  return (dispatch) => {
    dispatch(updateUserBegin());
    dispatch(endUserEdit());
    axiosClient
      .put(`/users/${userId}`, body)
      .then((result) => {
        dispatch(updateUserSuccess(result.data));
        return result.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(updateUserError(error));
      });
  };
}
