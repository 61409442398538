import { OPEN_CHARGE_USER_MODAL, CLOSE_CHARGE_USER_MODAL } from './chargeUserActions';

const initialState = {
  modalOpen: false,
};

export default function chargeUserReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CHARGE_USER_MODAL:
      return {
        ...state,
        chargeCard: action.payload.chargeCard,
        modalOpen: true,
      };
    case CLOSE_CHARGE_USER_MODAL:
      return {
        ...state,
        modalOpen: false,
      };
    default:
      return state;
  }
}
