export const OPEN_CHANGE_LOCATION_MODAL = 'OPEN_CHANGE_LOCATION_MODAL';
export const CLOSE_CHANGE_LOCATION_MODAL = 'CLOSE_CHANGE_LOCATION_MODAL';

export const openChangeLocationModal = () => ({
  type: OPEN_CHANGE_LOCATION_MODAL,
});

export const closeChangeLocationModal = () => ({
  type: CLOSE_CHANGE_LOCATION_MODAL,
});
