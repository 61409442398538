export const OPEN_CHARGE_USER_MODAL = 'OPEN_CHARGE_USER_MODAL';
export const CLOSE_CHARGE_USER_MODAL = 'CLOSE_CHARGE_USER_MODAL';

export const openChargeUserModal = (chargeCard) => ({
  type: OPEN_CHARGE_USER_MODAL,
  payload: { chargeCard },
});

export const closeChargeUserModal = () => ({
  type: CLOSE_CHARGE_USER_MODAL,
});
