import 'typeface-roboto';

import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';

import App from './App';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';

Sentry.init({
  dsn: 'https://ff4539e28de249499f290a7a80ffacfc@o547770.ingest.sentry.io/5670434',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
