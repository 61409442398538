import axiosClient from '../../utils/API';
import { authSetToken } from '../auth/authActions';

export const USER_LOGIN_BEGIN = 'USER_LOGIN_BEGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_SET_TOKEN = 'USER_SET_TOKEN';

export const userLoginBegin = () => ({
  type: USER_LOGIN_BEGIN,
});

export const userLoginSuccess = () => ({
  type: USER_LOGIN_SUCCESS,
});

export const userLoginFailure = (error) => ({
  type: USER_LOGIN_FAILURE,
  payload: { error },
});

export const userSetToken = (token) => ({
  type: USER_SET_TOKEN,
  payload: { token },
});

export function postUserLogin({ email, password }) {
  return (dispatch) => {
    dispatch(userLoginBegin());
    axiosClient
      .post('/auth/login', {
        email,
        password,
      })
      .then((result) => {
        dispatch(userLoginSuccess());
        dispatch(authSetToken(result.data.token, result.data.user));
        return result.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(userLoginFailure(error));
      });
  };
}
