import * as Sentry from '@sentry/browser';

import Button from '@mui/material/Button';
import React from 'react';

// Sentry.init({
//  dsn: "https://c0742d3079a54d56b9e3b335084fc6e3@sentry.io/1450021"
// });
// should have been called before using it here
// ideally before even rendering your react app

class SentryErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</Button>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default SentryErrorBoundary;
