import axios from 'axios';

const axiosClient = axios.create({
  baseURL: 'https://api.firstdueondemand.com/',
  // baseURL: 'http://localhost:9090/',
  responseType: 'json',
});

axiosClient.defaults.headers.common['Authorization'] = '';

export default axiosClient;
